<template>
  <CompanySelfSignupForm v-if="!isUserSelfSignUpInfoCompleted" />
  <template v-else>
    <v-idle
      :loop="true"
      :wait="parseInt(IDLE_ATTRIBUTES.wait)"
      @idle="onIdle"
    />
    <TrialPeriodExpired v-if="isCompanyTrialExpired" />
    <template v-else>
      <div v-if="userFetched && userPresent" class="h-screen flex bg-gray-100">
        <TopBar :toggle-side-bar-collapsed="toggleSideBarCollapsed" />
        <Notifications />
        <div class="flex flex-1">
          <div
            class="sidebar-container"
            :class="{ 'open-menu': isSidebarPermanentlyOpen }"
          >
            <Sidebar
              :sidebar-open="toggleTempSidebar"
              :is-sidebar-permanently-open="isSidebarPermanentlyOpen"
              :is-sidebar-temporary-open="sidebarOpen"
              :trial-mode="companyOnTrial"
              :demo-mode-until="company.demoModeUntil"
              :toggle-side-bar-collapsed="toggleSideBarCollapsed"
            />
          </div>

          <div
            class="app-wrapper"
            :class="[{ openSidebar: shoppingListVisible }]"
          >
            <transition name="fade">
              <VersionNotification v-if="showNewVersionAlert" />
            </transition>
            <div class="main-wrapper">
              <AwayBanner v-if="user.substitute" :user="user" />
              <Content />
            </div>
            <transition v-if="isFeatureAllowed('shoppingList')" name="fade">
              <ShoppingListSlideOver class="right-sidebar" />
            </transition>
            <Downloads />
          </div>
        </div>
      </div>
    </template>

    <Dialog
      v-if="showDialog"
      :show-dialog="showDialog"
      :close-dialog="closeDialog"
      :dialog-title="dialogTitle"
      :dialog-body-text="dialogBodyText"
      :success="userFetched"
      :button-text="$t('auth.backToLogin')"
      :on-click-handler="logoutUser"
    />
  </template>
</template>

<script>
import Sidebar from "@/components/Home/Sidebar";
import Content from "@/components/Home/Content";
import TopBar from "@/components/Home/TopBar";
import { mapActions, mapState } from "vuex";
import { AUTH_MODULE, GET_USER, LOGOUT_USER } from "@/store/Auth/types";
import { dialogMixin } from "@/components/mixins/index.js";
import Notifications from "@/components/Notifications";
import { ShoppingListSlideOver } from "@/components/ShoppingList/index.js";
import { SHOPPING_LIST_MODULE } from "@/store/ShoppingList/types.js";
import Downloads from "@/components/shared/Downloads.vue";

import {
  differenceBetweenDatesInSecs,
  getItemFromLocalStorage,
  isObjectEmpty,
  removeItemInLocalStorage,
  addScriptOnPageLoad,
} from "@/utils/utility_methods";
import { VersionNotification } from "@/components/general/index.js";
import { DASHBOARD_MODULE } from "@/store/Dashboard/types";
import { newVersionApplied } from "@/utils/utility_methods.js";
import AwayBanner from "@/components/Home/AwayBanner.vue";
import { CompanyManagementApisMixin } from "@/components/mixins";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import CompanySelfSignupForm from "@/components/Dashboard/CompanySelfSignupForm";
import TrialPeriodExpired from "@/components/Home/TrialPeriodExpired";
import { IDLE_ATTRIBUTES } from "@/utils/constants.js";
export default {
  components: {
    TopBar,
    Content,
    Sidebar,
    Notifications,
    VersionNotification,
    AwayBanner,
    ShoppingListSlideOver,
    Downloads,
    CompanySelfSignupForm,
    TrialPeriodExpired,
  },
  mixins: [dialogMixin, CompanyManagementApisMixin],
  data() {
    return {
      sidebarOpen: false,
      userFetched: false,
      newVersionAvailable: false,
      isSidebarCollapsed: false,
      showSelfSignUpCompanyModal: false,
      isSidebarPermanentlyOpen: false,
    };
  },
  computed: {
    ...mapState(DASHBOARD_MODULE, ["showNewVersionAlert"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    ...mapState(SHOPPING_LIST_MODULE, ["shoppingListVisible"]),
    userPresent() {
      return !isObjectEmpty(this.user);
    },
    companyOnTrial() {
      return this.company && this.company.demoModeUntil != null;
    },
    isCompanyTrialExpired() {
      if (!this.company.demoModeUntil) return false;

      const demoDate = new Date(this.company.demoModeUntil).getTime();
      const dateToday = new Date().getTime();
      return demoDate < dateToday;
    },
  },
  watch: {
    isUserSelfSignUpInfoCompleted: {
      handler(newVal, oldVal) {
        if (newVal && oldVal != newVal) {
          this.fetchUser();
        }
      },
    },
  },
  created() {
    newVersionApplied();
    this.IDLE_ATTRIBUTES = IDLE_ATTRIBUTES;
  },
  mounted() {
    if (process.env.VUE_APP_HUBSPOT_URL)
      addScriptOnPageLoad(process.env.VUE_APP_HUBSPOT_URL);
    if (this.isUserSelfSignUpInfoCompleted) {
      this.fetchUser();
    } else {
      this.toggleSelfSignUpCompanyModal();
    }
  },
  unmounted() {
    this.socket.ws?.onclose(false);
  },
  methods: {
    ...mapActions(AUTH_MODULE, {
      getUser: GET_USER,
      logoutUser: LOGOUT_USER,
    }),
    onIdle() {
      window.location.reload();
    },
    toggleTempSidebar(value) {
      this.sidebarOpen = value;
    },
    redirectToSavedRoute() {
      const routeToRedirect = getItemFromLocalStorage("routeToRedirect");
      if (routeToRedirect) {
        const now = new Date().toUTCString();
        const differenceInSecs = differenceBetweenDatesInSecs(
          routeToRedirect.time,
          now
        );

        if (differenceInSecs <= 60) {
          this.$router.push({ path: routeToRedirect.path });
        }
        removeItemInLocalStorage("routeToRedirect");
      }
    },
    fetchUser() {
      this.setApiLoading({ key: "component", value: true });
      this.getUser()
        .then(() => {
          this.showDialog = false;
          this.userFetched = true;
          // this.addTrackingScript();
          this.socket.connect();

          this.redirectToSavedRoute();
        })
        .catch(() => {
          this.showDialog = true;
          this.dialogTitle = this.$t("userInfo.userCannotBeFetched");
          this.dialogBodyText = this.$t("userInfo.somethingwWentWrong");
          this.userFetched = false;
        })
        .finally(() => {
          this.setApiLoading({ key: "component", value: false });
        });
    },
    addTrackingScript() {
      const trackingScript = document.getElementById("tracking_script");

      if (!trackingScript) {
        const trackingId = process.env.VUE_APP_TRACKING_ID;
        const userEmail = this.user.email;
        const scriptTag = document.createElement("script");

        const script = `
        (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
        vgo('setAccount', '${trackingId}');
        vgo('setTrackByDefault', true);
        vgo('setEmail', '${userEmail}');
        vgo('process');
      `;

        scriptTag.setAttribute("type", "text/javascript");
        scriptTag.setAttribute("id", "tracking_script");
        const inlineCode = document.createTextNode(script);
        scriptTag.appendChild(inlineCode);
        document.head.appendChild(scriptTag);
      }
    },
    toggleSideBarCollapsed() {
      // this.isSidebarCollapsed = !this.isSidebarCollapsed;
      this.isSidebarPermanentlyOpen = !this.isSidebarPermanentlyOpen;
    },
    setIsSidebarCollapsed(val) {
      // this.isSidebarCollapsed = val;
      this.isSidebarPermanentlyOpen = val;
    },
    toggleSelfSignUpCompanyModal() {
      this.showSelfSignUpCompanyModal = !this.showSelfSignUpCompanyModal;
    },
  },
};
</script>
<style scoped>
.main-wrapper {
  @apply relative z-0 overflow-y-auto p-4 h-screen transition-all duration-500 ease-in-out;
}
.app-wrapper {
  @apply flex flex-col w-0 flex-1 overflow-hidden pt-[64px] transition-all duration-500;
}

.sidebar-container {
  @apply transition-all duration-500 w-0 lg:w-[88px] fixed lg:relative z-50;
}

.sidebar-container.open-menu {
  @apply w-[300px];
}

.app-wrapper.open {
  @apply pl-0;
}

.app-wrapper.openSidebar .main-wrapper {
  @apply pr-[410px];
}

.right-sidebar {
  @apply transition-[right] duration-500 ease-in-out;
}

.app-wrapper .right-sidebar {
  @apply -right-[400px];
}

.app-wrapper.openSidebar .right-sidebar {
  @apply right-0;
}

.v-idle {
  display: none;
}
</style>
