<template>
  <header id="header">
    <div class="wrapper-left flex items-center">
      <div class="menu-button" @click="toggleSideBarCollapsed">
        <MenuAlt2Icon class="menu-icon" aria-hidden="true" />
      </div>

      <dt class="sr-only">Company</dt>
      <dd class="header-left-container">
        <Header />
        <OfficeBuildingIcon class="office-building" aria-hidden="true" />
        <span class="items-center hidden md:flex">{{ company.name }}</span>
        <div class="hidden 2xl:flex">
          <span class="mx-2 flex items-center">/</span>
          <div
            v-if="user.departments && user.departments.length > 1"
            class="flex items-center"
          >
            <span class="mr-1">{{ $t("home.multipleDepartments") }}</span>
          </div>
          <span v-else-if="user.departments && user.departments.length === 1">
            {{ user.departments[0].name }}
          </span>
          <span v-else>{{ $t("global.errors.noDepartment") }}</span>
        </div>
        <span
          v-if="!isUserOnline"
          class="text-red-400 flex justify-between ml-4"
        >
          <StatusOfflineIcon class="h-6 w-6 mr-2" />
          {{ $t("global.errorPages.noInternet") }}
        </span>
      </dd>
    </div>

    <div class="header-right-container">
      <div class="flex-auto flex justify-end items-center">
        <div class="flex items-center flex-shrink-0 md:ml-2">
          <!--  Search Wrapper -->
          <div class="hidden sm:flex">
            <Searchbar />
          </div>
          <!--  End Search Wrapper -->
        </div>
        <div class="flex items-center flex-shrink-0">
          <!--  My Budget Wrapper -->
          <div class="rounded-full ml-4 border border-gray-200">
            <dl class="flex flex-col sm:flex-row sm:flex-wrap">
              <dd
                v-if="valuesForBarExist && isFeatureAllowed('budget')"
                class="flex items-center text-sm text-gray-500 font-medium py-0.5 sm:mr-1 sm:mt-0 md:mb-0"
              >
                <CashIcon
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-primary xl:flex hidden ml-3"
                  aria-hidden="true"
                />
                <div class="content hidden xl:block">
                  {{ $t("dashboard.headerPersonalBudget") }}
                </div>
                <div class="w-36 xl:w-48 items-center ml-3 mr-2 flex gap-x-1">
                  <HProgressBar
                    :level="3"
                    :total="parseFloat(user.budget.amount)"
                    :approved="parseFloat(user.budget.usedBudget)"
                    :pending="parseFloat(user.budget.pendingBudget)"
                    :labels="false"
                    :in-bar-labels="true"
                    color="bg-primary"
                    color-approved="bg-yellow-500"
                    class="overflow-hidden flex w-36"
                  />
                  <InformationPopover class="popover">
                    <template #header>
                      {{ $t("dashboard.budgetBar.popupHeader") }}
                    </template>
                    <template #body>
                      <p class="mb-4">
                        {{ $t("dashboard.budgetBar.popupText") }}
                      </p>
                      <span
                        v-if="company && user.budget && user.budget.amount > 0"
                        class="text-primary"
                        >{{ $t("dashboard.budgetBar.status1") }}:
                        <i18n-n
                          :value="
                            parseFloat(user.budget.amount) -
                            parseFloat(user.budget.remainingBudget)
                          "
                          format="currency"
                          :locale="currencyToLocale(company.currency)"
                        />
                        /
                        <i18n-n
                          :value="parseFloat(user.budget.amount)"
                          format="currency"
                          :locale="currencyToLocale(company.currency)"
                        />
                      </span>
                      <span
                        v-if="company && user.budget && user.budget.amount > 0"
                        class="text-primary"
                        >{{ $t("dashboard.budgetBar.status2") }}:
                        <i18n-n
                          :value="parseFloat(user.budget.remainingBudget)"
                          format="currency"
                          :locale="currencyToLocale(company.currency)"
                        />
                      </span>
                    </template>
                  </InformationPopover>
                </div>
              </dd>
            </dl>
          </div>

          <!-- End My Budget Wrapper -->

          <!-- Notification Icon -->

          <button
            v-if="false"
            class="rounded-full background-gray bg-gray-100 flex h-8 w-8 relative ml-4 justify-center items-center focus:outline-none"
          >
            <span
              class="absolute top-0 right-0 text-white bg-red-500 w-5 h-5 text-xs flex items-center justify-center rounded-full -mr-1 -mt-1 font-semibold"
              >3</span
            >
            <BellIcon class="h-5 w-5 text-gray-800" />
          </button>

          <!-- End Notification Icon -->

          <button
            class="p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none"
          >
            <span class="sr-only">{{ $t("home.viewNotifications") }}</span>
            <BellIcon v-if="false" class="h-6 w-6" aria-hidden="true" />
          </button>
          <div
            v-if="isFeatureAllowed('shoppingList') && user.roles.includes('BU')"
            class="relative mr-4 lg:mr-6 flex items-center px-3 py-2 bg-transparent rounded text-gray-500 hover:text-primary hover:bg-gray-100 cursor-pointer"
            @click="toggleList"
          >
            <ShoppingListSVG class="h-6 w-6" />
            <div
              class="absolute rounded-full text-xs px-2 py-0.5 -top-1 -right-2 bg-primary text-white"
            >
              {{ countShoppingLists }}
            </div>
          </div>
          <!-- Profile dropdown -->
          <Menu as="div" class="ml-3 relative">
            <div>
              <MenuButton
                class="flex items-center text-sm rounded-full focus:outline-none"
              >
                <span class="sr-only">{{ $t("home.openUserMenu") }}</span>
                <img
                  v-if="user.avatar"
                  class="h-8 w-8 rounded-full border border-gray-200"
                  :src="user.avatar"
                  alt=""
                />
                <div v-else>
                  <span
                    class="name-initials inline-flex items-center justify-center h-7 w-7 rounded-full"
                    :class="[avatarColor()]"
                  >
                    <span class="text-xs leading-none text-white">
                      {{ nameInitials(user) }}
                    </span>
                  </span>
                </div>

                <span
                  id="user-actions"
                  class="mx-2 text-sm text-gray-500 font-medium rounded-full font-sans truncate hidden sm:block"
                  >{{ user.firstName || "Name" }}
                </span>
                <ChevronDownIcon class="h-4 w-4 text-gray-400" />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
              >
                <div class="px-4 py-3">
                  <div
                    v-if="company.logo"
                    class="xl:hidden mb-2 flex items-center flex-shrink-0 justify-center"
                  >
                    <img :src="company.logo" class="h-10" :alt="company.name" />
                  </div>
                  <p class="text-gray-800 text-md truncate lg:hidden sm:block">
                    {{ user.firstName || "Name" }}
                  </p>
                  <p class="text-sm text-gray-400">
                    {{ $t("home.signedInAs") }}
                  </p>
                  <p class="text-sm font-medium text-gray-900 truncate">
                    {{ user.email }}
                  </p>
                </div>
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      ]"
                      @click="changeRoute('EditProfile', $event)"
                    >
                      {{ $t("home.editYourProfile") }}</a
                    >
                  </MenuItem>
                </div>
                <!-- <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      ]"
                      >{{ $t("home.configureConnections") }}</a
                    >
                  </MenuItem>
                </div> -->
                <div class="py-1">
                  <DropDown
                    class="block px-4 py-2"
                    :value="localValue()"
                    :on-change="onLocaleChange"
                    :list-options="localeOptions()"
                  />
                </div>
                <div class="py-1">
                  <!-- <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      ]"
                      >{{ $t("home.support") }}</a
                    >
                  </MenuItem> -->
                  <MenuItem v-slot="{ active }" class="group">
                    <button
                      type="button"
                      class="flex sign-out"
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-2 text-sm',
                      ]"
                      @click="onUserLogout"
                    >
                      <LogoutIcon
                        class="mr-3 h-5 w-5 text-gray-400 group-hover:text-red-500"
                        aria-hidden="true"
                      />
                      {{ $t("home.signOut") }}
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {
  OfficeBuildingIcon,
  CashIcon,
  BellIcon,
  LogoutIcon,
  ChevronDownIcon,
  MenuAlt2Icon,
  StatusOfflineIcon,
} from "@heroicons/vue/outline";

import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import ShoppingListSVG from "@/assets/images/ShoppingListOutline.vue";
import { mapState, mapGetters } from "vuex";
import { AUTH_MODULE } from "@/store/Auth/types";
import { localeMixin, userMixin } from "@/components/mixins/index.js";
import { mapActions, mapMutations } from "vuex";
import { LOGOUT_USER } from "@/store/Auth/types";
import Searchbar from "@/components/shared/Searchbar.vue";
import { USER_AVATAR_STYLE } from "@/utils/constants.js";
import {
  SHOPPING_LIST_MODULE,
  TOGGLE_VISIBLE_STATE,
} from "@/store/ShoppingList/types.js";
import { HProgressBar, InformationPopover } from "@/components/shared/index";
import { currencyToLocale, isObjectEmpty } from "@/utils/utility_methods.js";
import { COMPANY_MANAGEMENT_MODULE } from "@/store/CompanyManagement/types";
import Header from "@/components/Home/Sidebar/Header.vue";

export default {
  components: {
    ShoppingListSVG,
    OfficeBuildingIcon,
    CashIcon,
    Searchbar,
    BellIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    LogoutIcon,
    HProgressBar,
    InformationPopover,
    ChevronDownIcon,
    MenuAlt2Icon,
    Header,
    StatusOfflineIcon,
  },
  mixins: [localeMixin, userMixin],

  props: {
    toggleSideBarCollapsed: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      searchClosed: true,
      isUserOnline: navigator.onLine,
    };
  },
  computed: {
    ...mapState(AUTH_MODULE, ["user"]),
    ...mapState(COMPANY_MANAGEMENT_MODULE, ["company"]),
    ...mapGetters(SHOPPING_LIST_MODULE, ["countShoppingLists"]),
    isCurrentRouteDashboard() {
      return this.$route.name === "Dashboard";
    },
    valuesForBarExist() {
      if (this.user) {
        if (
          this.user?.budget?.amount > 0 &&
          this.user?.budget?.usedBudget &&
          this.user?.budget?.pendingBudget
        ) {
          return true;
        }
      }
      return false;
    },
  },
  mounted() {
    if (!isObjectEmpty(this.user)) {
      this.$i18n.locale = this.user.language.toLowerCase();
    }
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeUnmount() {
    window.removeEventListener("online", this.updateOnlineStatus);
    window.removeEventListener("offline", this.updateOnlineStatus);
  },
  methods: {
    ...mapActions(AUTH_MODULE, {
      onUserLogout: LOGOUT_USER,
    }),
    ...mapMutations(SHOPPING_LIST_MODULE, {
      toggleShoppingList: TOGGLE_VISIBLE_STATE,
    }),
    toggleList() {
      this.toggleShoppingList();
    },
    currencyToLocale,
    changeRoute(name, event) {
      event.preventDefault();
      this.$router.push({ name: name });
    },
    avatarColor() {
      if (this.user) {
        return USER_AVATAR_STYLE[this.user.colorAvatar];
      } else {
        return "bg-primary";
      }
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.isUserOnline = type === "online";
    },
  },
};
</script>

<style scoped>
.popover {
  margin-top: 5px;
}

.menu-icon {
  width: 26px;
  height: 26px;
}

#header {
  @apply fixed top-0 left-0 right-0 z-[60] h-[66px]  flex-shrink-0 flex bg-white  sm:py-0 shadow;
}

.menu-button {
  @apply text-primary cursor-pointer hover:text-primarydark transition ml-4 z-20;
}

.header-left-container {
  @apply items-center text-base font-semibold text-gray-500 flex;
}

.header-right-container {
  @apply flex-auto flex px-2;
}

.office-building {
  @apply flex-shrink-0 mr-1.5 h-6 w-6 text-gray-500 hidden md:flex;
}
</style>
