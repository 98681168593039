<template>
  <div class="z-0 inline-flex shadow-sm w-full">
    <ConfirmDialog
      :text="$t('general.newRequestButton.startFreshText')"
      :show-modal="showResetModal"
      :close-modal="onResetClose"
      :title="$t('general.newRequestButton.startFreshTitle')"
      :confirm-modal="onReset"
    />
    <button
      :class="btnClasses"
      class="truncate btn-request"
      v-on="{ click: !loading ? onClickHandler : null }"
    >
      <span v-if="showIcon && !loading" class="button-icon">
        <FastForwardIcon v-if="requestInProgress()" />
        <PurchaseRequestPlusSVG v-if="!requestInProgress()" />
      </span>
      <div class="button-label">
        {{
          requestInProgress()
            ? $t("general.newRequestButton.continue")
            : $t("general.newRequestButton.newRequest")
        }}
      </div>
      <span v-if="loading" class="mr-2">
        <svg
          class="animate-spin h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path></svg
        >>
      </span>
      <slot />
    </button>
    <Menu
      v-if="requestInProgress()"
      as="span"
      class="mt-2 relative block z-50 mr-4 -ml-2"
    >
      <MenuButton
        class="relative items-center py-2 px-1 !rounded-r !bg-white text-primary hover:text-primarydark transition duration-500 ease-in-out dropdown-icon"
      >
        <span class="sr-only">More</span>
        <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute right-0 mt-2 w-40 rounded-md shadow-lg !bg-white focus:outline-none"
        >
          <div class="py-1">
            <MenuItem
              v-for="item in items"
              :key="item.name"
              v-slot="{ active }"
            >
              <span
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm cursor-pointer',
                ]"
                @click="item.action"
              >
                {{ item.name }}
              </span>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>
<script>
import { FastForwardIcon } from "@heroicons/vue/outline";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import PurchaseRequestPlusSVG from "@/assets/images/PurchaseRequestPlusSVG.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import {
  PURCHASE_REQUEST_MODULE,
  RESET_CURRENT_REQUEST,
} from "@/store/PurchaseRequest/types";
import { mapState, mapMutations } from "vuex";
export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    FastForwardIcon,
    PurchaseRequestPlusSVG,
    ConfirmDialog,
  },
  props: {
    showIcon: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    sizeClass: {
      type: String,
      default: "md",
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showResetModal: false,
    };
  },
  computed: {
    btnClasses() {
      const classes = {
        btn: true,
        "btn-full": this.full,
        "btn-loading": this.loading,
        "btn-data-exists": this.requestInProgress(),
      };

      classes[`btn-${this.color}`] = true;
      classes[`btn-${this.sizeClass}`] = true;

      return classes;
    },

    ...mapState(PURCHASE_REQUEST_MODULE, ["currentRequest"]),
    items() {
      return [
        {
          name: this.$t("general.newRequestButton.continue"),
          startOver: false,
          action: this.onClickHandler,
        },
        {
          name: this.$t("general.newRequestButton.startFresh"),
          startOver: true,
          action: this.confirmReset,
        },
      ];
    },
  },
  methods: {
    ...mapMutations(PURCHASE_REQUEST_MODULE, {
      resetRequest: RESET_CURRENT_REQUEST,
    }),
    onResetClose() {
      this.showResetModal = false;
    },
    onClickHandler() {
      const redirectHash = { name: "NewRequest" };
      if (this.requestInProgress()) {
        redirectHash.name = "PurchaseRequest";
      }
      const source = this.currentRequest?.items[0]?.source;
      if (source) {
        redirectHash.name = "Catalogue";
        redirectHash.query = { cart: true };
      }
      this.$router.push(redirectHash);
    },

    onReset() {
      this.showResetModal = false;
      this.startFresh();
    },

    startFresh() {
      this.resetRequest();
      this.onClickHandler();
    },
    requestInProgress() {
      const { items, category, supplier } = this.currentRequest;
      return items.length || category || supplier;
    },
    confirmReset() {
      this.showResetModal = true;
    },
  },
};
</script>

<style scoped>
.btn {
  @apply px-4  focus:outline-none inline-flex items-center justify-center transition duration-500 ease-in-out;
}
.btn-xs {
  @apply text-xs;
}
.btn-lg {
  @apply text-lg;
}
.btn-data-exists {
}
.btn-full {
  @apply w-full;
}

.btn-primary {
  @apply bg-white hover:text-primarydark text-primary tracking-widest  font-semibold my-2 mx-2;
  height: 44px;
}
.btn-danger {
  @apply bg-red-500 hover:bg-red-600 text-white;
}
.btn-gray {
  @apply bg-white hover:bg-gray-50 text-gray-700;
}
.btn-primary-outline {
  @apply bg-white border-teal-500 hover:bg-teal-500 text-teal-500 hover:text-white;
}
.btn-danger-outline {
  @apply bg-white border-red-500 hover:bg-red-500 text-red-500 hover:text-white;
}

.btn-loading {
  @apply cursor-not-allowed;
}

.dropdown-icon {
  height: 44px;
  margin-left: -6px;
  border-radius: 0;
}

.button-icon {
  @apply h-5 w-5 mx-3 shrink-0 transition duration-500;
}

.button-label {
  @apply tracking-normal transition duration-500 overflow-hidden truncate;
}

.btn-request {
  @apply mb-4 mx-4 rounded;
}

.btn-data-exists {
  @apply rounded-r-none;
}
</style>
